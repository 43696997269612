<template>
    <div class="product-card" >
      <!-- <img :src="product.image" alt="product image" class="product-image" /> -->

      <el-image class="product-image" :src="getUrl(product.url)"></el-image>
      <div class="text">{{ product.name }}</div>

      
    </div>
  </template>
  
  <script setup>
 import { defineProps } from 'vue';
 
 const props = defineProps({
  product: {}
 })
 const getUrl=(url)=>{
  return process.env.VUE_APP_BASE_PATH+"/"+url
 }

  </script>
  
  <style scoped>
  .product-card {
    width: 350px;
    height: 200px;
    cursor: pointer;
    transition: transform 0.3s;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .product-card:hover {
    transform: scale(1.05);
  }
  .product-image {
    width: 100%;
    height: 11rem;
    /* object-fit: cover; */

    position: absolute;
    border-radius: .375rem;
  }
  .text {
    position: absolute;
    color: rgb(0, 0, 0);
    font-size: 20px;
    bottom: 12px;
    right: 0;
    width: 96%;
    text-align: right;
    background: linear-gradient(to top, #fdfdfda8 0%, #ffffff00 100%);
    line-height: 50px;
    padding-right: 4%;
  }
  </style>
  