<!-- ProductList.vue -->
<template>
  <div class="home">
    <header-bar />
    <div class="banner-topImg"></div>
      <div class="search-container">
        <div class="title">插件市场</div>
        <div class="title2">加速创意实现 梦想快人一步</div>
        <el-input
          placeholder="搜索就是这么简单"
          v-model="productSearch.name"
          clearable
          class="search-box"
          prefix-icon="Search"
          @keyup.enter="list"
        />
        <div class="products">
            <product-card
            v-for="product in products"
            :key="product.id"
            :product="product"
            @click="goToDetail(product.ID)"
            />
        </div>
      </div>

      <div class="downproducts">
        <el-row 
          class="listRow" 
          :gutter="10" 
        >
          <el-col
            v-for="product in downproducts" 
            :key="product.id" 
            :product="product" 
            :span="6"
            class="listCol"
          >
            <el-card class="listCard" :body-style="{ padding: '0px' }" shadow="hover">
                <el-badge :value="product.tag" class="listTag"><el-image
                @click="goToDetail(product.ID)"
                :src="getUrl(product.url)"
                :fit="scale-down"
                class="listImg"
              ></el-image></el-badge>
                
              <template #footer>
                <div class="text-content">
                  <span class="left-text">{{product.name}}</span>
                  <span class="right-text" style="color: #f5202a;">￥{{product.price}}</span>
                </div>
                <el-text truncated>{{product.describe}}</el-text>
              </template>
            </el-card>
          </el-col>
        </el-row>
      </div>
  </div>
  </template>
  
  <script setup>
    import HeaderBar from "@/components/Header.vue";
    import ProductCard from "@/components/ProductCard.vue";
    import LoginCard from "@/components/LoginCard.vue";

  import {
    pluginList
  } from '@/utils/plugin'
  import { ref, computed, onMounted } from 'vue'
  import { Search, ShoppingCart, Picture } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  import {useRoute,useRouter} from 'vue-router'
  const router = useRouter()
  const productSearch=ref({
    "isShow":false,
    "name":'',
  })
  const products=ref([
          
        ])
const downproducts=ref([
          
        ])
  // 商品数据

  
  // 搜索和排序状态
  const sortBy = ref('price-asc')
const getUrl=(url)=>{
  return process.env.VUE_APP_BASE_PATH+"/"+url
 }
onMounted(async ()=>{
    pluginList({isShow:true}).then(res=>{
    products.value=res.data
    // console.log(JSON.stringify(res))
  })
  list()
})
  const list=()=>{
    pluginList(productSearch.value).then(res=>{
      downproducts.value=res.data
    })
  }



const goToDetail=(id)=> {
  // if (window.localStorage.getItem('user')==null){
  //   ElMessage.info('请登陆后再查看')
  //   return
  // }
  router.push({name:"ProductDetail",path:`/product`,query: {"id":id}})
  
      }


  </script>
  
  <style scoped>
  
  .home {
    text-align: center;
    background: #fff;
    min-height: 100vh;
  }

  :deep(.el-input__wrapper){
    box-shadow: none;
  }
  :deep(.el-input__wrapper:hover){
    box-shadow: 0 0 0 1px var(--el-color-primary) inset;
  }
  :deep(.el-input .el-input__icon){
    margin: 10px;
  }
  :deep(.el-card__footer){
    border: none;
  }
  :deep(.el-badge__content.is-fixed){
    right: auto;
    left: -67px;
    top: 13px;
    color: #ffffff;
    background-color: #1c6ff79c;
    border-radius: .375rem 0 .375rem 0;
    font-size: .75rem;
    line-height: 1rem;
    padding: .25rem .5rem;
    border: none;
  }

  .banner-topImg{
    background-image: url(../assets/login_background.f7a2e6fc.svg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: 90% 100%;
    background-repeat: no-repeat;
    opacity: .3;
  }
  
  .search-container {
    margin-top: 60px;
    background: linear-gradient(99deg, #cee8ff, #cee8ff 0, rgba(234, 241, 242, 0) 49%, #ffeaea 98%, #ffeaea 0), linear-gradient(180deg, #eff7ff, #eff7ff 0, rgba(255, 239, 239, 0) 100%, rgba(255, 239, 239, 0) 0), #f0f6ff;
    height: 500px;
    width: 100%;
    .search-box{
        margin: 20px auto;
        height: 60px;
        width: 480px;
    }
    .title{
      padding: 50px 0 10px 0;
      font-size: 2.25rem;
      line-height: 2.5rem;
      font-weight: 500;
      text-align: center;
    }
    .title2{
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      padding: 10px 0;
    }
  }
  .products {
    display: flex;
    justify-content: center;
    gap: 70px;
    width: 70%;
    margin: auto;
    padding: 20px;
  }
  .downproducts {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    background-color: #fff;

    .listRow{
      max-width: 80rem !important;
      /* min-width: 50rem !important; */
      display: flex;
      flex-flow: row wrap;
      margin-top: 30px;

      .listCol{
        margin-bottom: 10px; /* 下间隔 */
      }

      .listCard{
        height: 100%;
        border: none;
        
        .listImg{
          width: 100%;
          max-height: 13rem;
          border-radius: .375rem!important;
        }
      }

      .text-content {
        display: flex;
        justify-content: space-between; /* 水平方向两端对齐 */
        align-items: center; /* 垂直方向居中对齐 */
        padding: 7px 0;
        .left-text {
          flex: 1; /* 占据剩余空间 */
          text-align: left; /* 文字靠左 */
        }
        .right-text {
          flex: 0; /* 固定宽度 */
          text-align: right; /* 文字靠右 */
        }
      }
    }
  }
  </style>
  