import { createRouter, createWebHistory } from "vue-router";
import ProductDetail from "../views/ProductDetail.vue";
import HelloWorld from "../views/HelloWorld.vue";
const routes = [
  { path: "/", name: "HelloWorld", component: HelloWorld },
  { path: "/product", name: "ProductDetail", component: ProductDetail },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
