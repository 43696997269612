<template>
  <Header />
  <div class="common-layout">
    <div class="main">
      <el-container class="product">
        <el-aside class="productLeft">
          <img src="https://qmplusimg.henrongyi.top/task-plugin-20240325.jpg">
        </el-aside>
        <el-main class="productRight">
          <el-container>
            <el-header>
              <div class="left-info">
                <b class="info-title">{{ product.name }}</b><br>
                <span style="color: rgb(245, 32, 42);">价格: ￥{{ product.price }}</span>
              </div>
              <!-- <div class="right-avatar">
                <el-avatar :src="user.headerImg" :size="50"></el-avatar>
                <span class="avatar-name">{{user.nickName}}</span>
              </div> -->
            </el-header>
            <p class="describe">{{product.describe}}</p>
            <el-divider />
            <el-button type="primary" class="buy">购 买</el-button>
          </el-container>
        </el-main>
        <el-divider />
        <!-- <el-card class="box-card"><RichView v-model="product.desc" /></el-card> -->
          <div class="content">
            <RichView v-model="product.desc"  width="100%" />
          </div>

      </el-container>







    </div>
  </div>

  </template>
  
  <script setup>
  import Header from "@/components/Header.vue";
  import RichView from '@/components/richtext/rich-view.vue'
  import{onMounted, ref} from 'vue'
  import {
    pluginDetail
  } from '@/utils/plugin'
   import { useRoute } from 'vue-router'; 
const { query } = useRoute();
  const product=ref({})
    pluginDetail({"id":query.id}).then(res=>{
      product.value=res.data
    })
const user=ref({})
if (window.localStorage.getItem('user')!=null){
  user.value=JSON.parse(window.localStorage.getItem("user"))
  }
  </script>





  
  <style scoped>
  .main{
    display: flex;
    justify-content: center;
    background-color: #e5e7eb;
    min-height: 100vh;
  }
  .product{
    max-width: 80rem !important;
    display: flex;
    flex-flow: row wrap;
    background-color: #fff;
    padding-top: 150px;

    .productLeft{
      width: 60%;
      img{
        width: 90%;
        border-radius: .375rem;
        margin: 0 5%;
      }
    }
    .productRight{
      width: 40%;
      margin-top: 100px;

      .left-info {
        float: left;
        width: 70%;
        .info-title{
          font-size: 1.875rem;
            line-height: 2.25rem;
        }
      }
    
      .right-avatar {
        float: right;
        width: 30%;
        .avatar-name{
          position: relative;
          top: -18px;
          font-size: 14px;
          padding-left: 5px;
          font-weight: bold;
        }
      }

      .describe{
        font-size: 14px;
        color: #6b7280;
        padding-top: 50px;
      }

      .buy{
        width: 230px;
        height: 50px;
        font-size: 18px;
        margin-left: 50%;
      }
    }
  }

/* .el-header{
  --el-header-padding:0;
} */

.content{
    width: 100%;
    background-color: #fff;
    max-width: 80rem !important;
}

  </style>
  