<template>
  <div class="right-avatar" v-if="!isShow">
    <el-avatar :src="user.headerImg" :size="50"></el-avatar>
    <span class="avatar-name">{{user.nickName}}</span>
    <el-button style="margin-left: 10px" plain @click="loginout">退出</el-button>
  </div>
  <el-button plain @click="loginVisible=true" v-if="isShow">登录</el-button>
  <el-button @click="registerVisible = true" v-if="isShow" type="primary">注册</el-button>
  <el-dialog  v-model="loginVisible" title="欢迎回来" width="420px" align-center>
    <el-form ref="loginForm"
              :model="loginFormData"
              :rules="rules"
              :validate-on-rule-change="false"
              @keyup.enter="submitForm">
      <el-form-item label="" prop="username">
        <el-input v-model="loginFormData.username" placeholder="请输入账号/邮箱" />
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input v-model="loginFormData.password" type="password" placeholder="请输入密码" autocomplete="off" show-password/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button class="loginButton" type="primary" @click="submitForm">
          登录
        </el-button>
      </div>
    </template>
  </el-dialog>
  

  <el-dialog v-model="registerVisible" title="注册新账号" width="420px" align-center>
    <el-form ref="regForm" :model="regFormData" :rules="regRules">
      <el-form-item label="" prop="userName">
        <el-input v-model="regFormData.userName" placeholder="请输入账号" />
      </el-form-item>
      <el-form-item label="" prop="nickName">
        <el-input v-model="regFormData.nickName" placeholder="请输入昵称" />
      </el-form-item>
      <el-form-item label="" prop="passWord">
        <el-input v-model="regFormData.passWord" type="password" placeholder="请输入密码" autocomplete="off" show-password/>
      </el-form-item>
      <el-form-item label=""prop="newpassword" >
        <el-input v-model="regFormData.newpassword" type="password" placeholder="请再次输入密码" autocomplete="off" show-password/>
      </el-form-item>
      <!-- <el-form-item label="" >
        <el-input v-model="form.email" placeholder="请输入邮箱" />
      </el-form-item>
      <el-form-item label="" >
        <el-input v-model="form.emailCode" placeholder="请输入邮箱验证码">
          <template #append>
            <el-button 
              :disabled="isButtonDisabled"
              @click="handleGetVerificationCode"
            >
            {{ buttonText }}
            </el-button>
          </template>
        </el-input>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button class="registerButton" type="primary" @click="regsubmitForm">
          注册
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
  defineOptions({
    name: 'Login'
  })
  
import {login,register} from '@/api/user.js'
import { ElMessage } from 'element-plus';
import { reactive, ref } from 'vue'
const formLabelWidth=ref('50%')
const loginVisible=ref(false)
const loginForm=ref(null)
const regForm=ref(null)
const regFormData=reactive({
  userName: '',
  passWord: '',
  newpassword:'',
})
const isShow=ref(true)
const loginFormData=reactive({
  username: '',
  password: '',
  captcha: '',
  captchaId: '',
  openCaptcha: false
})
// 验证函数
const checkUsername = (rule, value, callback) => {
    if (value.length < 5) {
      return callback(new Error('请输入正确的用户名,长度大于5'))
    } else {
      callback()
    }
  }
  const checkPassword = (rule, value, callback) => {
    if (value.length < 6) {
      return callback(new Error('请输入正确的密码,长度大于6'))
    } else {
      callback()
    }
  }
  const checkPassword1 = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== regFormData.passWord) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
  }
const rules=reactive({
  username: [{ validator: checkUsername, trigger: 'blur' }],
  password: [{ validator: checkPassword, trigger: 'blur' }],
})
const regRules = reactive({
    userName: [{ validator: checkUsername, trigger: 'blur' }],
    passWord: [{ validator: checkPassword, trigger: 'blur' }],
    nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
    newpassword: [{ required: true, message: '请再次输入密码', trigger: 'blur' },{ validator: checkPassword1, trigger: 'blur' }]
  })
const user=ref({})
if(window.localStorage.getItem("user")!=null){
  user.value=JSON.parse(window.localStorage.getItem("user"))
}
if (user.value.ID){
  isShow.value=false
}
const submitForm=async()=>{
  loginForm.value.validate(async (v) => {
      if (!v) {
        // 未通过前端静态验证
        ElMessage({
          type: 'error',
          message: '请正确填写登录信息',
          showClose: true
        })
        return false
      }

      // 通过验证，请求登陆
      const res = await login(loginFormData)
      // 登陆成功，设置用户信息和权限相关信息
      if (res.code==0){
        ElMessage.success('登录成功')
        loginVisible.value=false
        window.localStorage.setItem('token',res.data.token)
        window.localStorage.setItem('user',JSON.stringify(res.data.user))
        isShow.value=false
        user.value=res.data.user
      }
          
  })
}
const regsubmitForm = () => {
  regForm.value.validate(async (v) => {
    if (!v) {
      // 未通过前端静态验证
      ElMessage({
        type: 'error',
        message: '请正确填写注册信息',
        showClose: true
      })
      return false
    }
    var resp =await register(regFormData)
    if (resp.code!=0){
      ElMessage({
        type: 'error',
        message: resp.msg,
        showClose: true
      })
      return false
    }
    ElMessage({
      type: 'success',
      message: '注册成功,即将登录',
      showClose: true
    })
    registerVisible.value=false
    // 通过验证，请求登陆
    const res = await login({
      username: regFormData.userName,
      password: regFormData.passWord,
    })
      // 登陆成功，设置用户信息和权限相关信息
      if (res.code==0){
        ElMessage.success('登录成功')
        loginVisible.value=false
        window.localStorage.setItem('token',res.data.token)
        window.localStorage.setItem('user',JSON.stringify(res.data.user))
        isShow.value=false
        user.value=res.data.user
      }
    return true
  })
}
const loginout=()=>{
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('user')
  isShow.value=true
  user.value={}
  ElMessage.success('退出登录成功')
}
import { ElButton, ElInput } from 'element-plus';
const registerVisible= ref(false)
</script>
<style scoped>
:global(.el-dialog__header.show-close){
  font-weight: bold;
  
}
:global(.el-dialog.is-align-center){
  padding: 20px 24px;
}
:deep(.el-input__wrapper){
  border-radius: .25rem;
    font-size: .875rem;
    line-height: 1.25rem;
    border: none;
    background: #f0f0f0;
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    font-weight: 500;
    padding: 9px 0 10px 18px;
    box-shadow: none;
}
.loginButton{
  width: 100%;
  font-size: 16px;
  height: 40px;
  margin-bottom: 30px;
}
.right-avatar{
  display: flex;
  align-items: center;
}
</style>
  