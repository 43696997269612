import service from './request'

export const pluginList = (params) => {
  return service({
    url: '/plugin/listPlugin',
    method: 'get',
    params
  })
}
export const pluginDetail = (params) => {
  return service({
    url: '/plugin/detailPlugin',
    method: 'get',
    params
  })
}
